/* header * {
    border: thin solid white;
} */

header {
    /* border: thin solid black; */
}

.App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    user-select: none;/* Prevents text selection */
    /* position: fixed; */
    width: 100%;
    z-index:2;
}

.App-header>.container {
    height: 50px;
    max-width: 1200px;
    flex-grow: 1;
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 0 10px;
    backdrop-filter: blur(10px);
}

.icon {
    height: 40px;
    width: 40px;
    background-color: #cfc8c8;
    border-radius: 50%;
}



.App-header .left {
    display: flex;
    align-items: center;
    font-size: x-large;
}

.App-header .left>div {
    margin: 2px;
}

.App-header .left .main-logo {
    height: 50px;
    width: 50px;
    /* background-color: #b4a6a6; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-logo .icon-style {
    font-size: 30px;
    color : white;
}



.App-header .center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    /* place at the center */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.App-header .center>div {
    margin: 2px;
    padding: 0px 20px;
    cursor: pointer;
}



.App-header .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: medium;
}

.App-header .right>div {
    margin: 2px;
}

.App-header .right .mode-change {
    display: flex;
    justify-content: center;
    align-items: center;
    background:none;
    font-size: 1.5em;
    margin-right: 10px;
    height: 40px;
    width: 40px;
}
.App-header .right .mode-change> i {
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
}
.App-header .right .mode-change:hover {
    background-color: rgb(62, 60, 60, 0.5);
}
.App-header .right .mode-change:hover {
    /* transform: scale(1.1); */
    cursor: pointer;
}

.nav-menu {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    cursor: pointer;
    transition: 0.1s;
    transform: scale(0.8);
}

.nav-menu:hover {
    /* transform: scale(1.1); */
    
}

.nav-menu .line {
    width: 100%;
    border: 3px solid rgb(255, 255, 255);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 40px;
    transition: 0.3s;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}


.nav-menu .one {
    transform: translate(-50%, -50%) translateY(5px) scale(0.7);
}

.nav-menu .two {
    transform: translate(-50%, -50%) translateY(-5px) scale(0.7);
}


.nav-menu .one.open {
    transform: translate(-50%, -50%) rotate(45deg);
}

.nav-menu .two.open {
    transform: translate(-50%, -50%) rotate(-45deg);
}









.sidebar {
    position: absolute;
    top: 48px;
    right: 10px;
    height: 322px;
    width: 200px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 2;
    border-radius:  0px 0px 10px 10px;
    overflow: hidden;
    padding-top: 10px;
    transition: 0.1s;
    animation: sidebar-open 0.1s;
    border-top: thin solid white;
}

.sidebar.close {
    display: none;
}

.sidebar .container {
    width: 90%;
    height: 250px;
    /* border: thin solid black; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    width: 100%;
    justify-content: flex-start;
    text-align: center;
}
.sidebar .container div {
    padding: 5px;
    border-bottom: thin solid white;
    margin-bottom: 1px;
}
.sidebar .container div:hover {
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
}


/* here is the animation of sidebar opening */
@keyframes sidebar-open {
    0% {
        height: 0px;
    }

    100% {
        height: 322px;
    }
}










@media (max-width: 600px) {
    /* CSS code for screens smaller than 600px goes here */
    .App-header .center {
        display: none;
    }
}